import * as React from "react"
import { Script } from "gatsby"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import { Container, Box, Heading } from "../components/ui"
import { Trans } from 'gatsby-plugin-react-i18next'

export default function Page() {  
  return (
    <Layout title="Cookie Policy">
      <Box paddingY={5}>
        <Container width="narrow">
          <Heading as="h1">
            <Trans>Cookie Policy</Trans>
          </Heading>
          <Script 
            id="CookieDeclaration" 
            src="https://consent.cookiebot.com/3927a9b6-aea2-4167-becf-029de0198789/cd.js" 
            type="text/javascript">            
          </Script>
        </Container>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;